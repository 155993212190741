import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faInfoCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { forkJoin } from 'rxjs';
import { DashboardInformation } from '../dashboard-information/dashboard-information';
import { DashboardInformationService } from '../dashboard-information/dashboard-information.service';
import { KpiCategory } from '../kpi-category/kpi-category';
import { KpiCategoryService } from '../kpi-category/kpi-category.service';
import { KpiInfoDialogComponent } from '../shared-components/info-dialog/kpi-info-dialog.component';

@UntilDestroy()
@Component({
  selector: 'kpi-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  standalone: false,
})
export class AppHeaderComponent implements OnInit {
  @Input() public themeName: string | undefined;
  @Input() public appTitle: string | undefined;

  public readonly faSignOut = faSignOutAlt;
  public readonly faInfoCircle = faInfoCircle;

  public kpiCategories: KpiCategory[] | null = null;
  public isMenuCollapsed = true;
  private dashboardInformation: DashboardInformation | null = null;

  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService,
    private readonly kpiCategoryService: KpiCategoryService,
    private readonly dashboardInformationService: DashboardInformationService,
    private readonly modalService: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.retrieveData();
  }

  public openDashboardInformationDialog() {
    if (this.dashboardInformation === null) {
      return;
    }

    const modalRef = this.modalService.open(KpiInfoDialogComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.title = 'Informatie';
    modalRef.componentInstance.body = this.dashboardInformation.info;
  }

  public isLoggedIn() {
    return this.oauthService.hasValidAccessToken();
  }

  public isFixed() {
    return this.router.url.includes('/overview');
  }

  public logout() {
    this.isMenuCollapsed = true;
    this.oauthService.logOut();
  }

  private openDashboardInformationDialogFirstTime() {
    if (!localStorage.getItem('dashboardInformationShown')) {
      this.openDashboardInformationDialog();
      localStorage.setItem('dashboardInformationShown', 'true');
    }
  }

  private retrieveData() {
    forkJoin([
      this.kpiCategoryService.retrieveAll(),
      this.dashboardInformationService.retrieveAll(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([categories, dashboardInformation]) =>
          this.onDataRetrieved(categories, dashboardInformation),
      });
  }

  private onDataRetrieved(categories: KpiCategory[], dashboardInformation: DashboardInformation) {
    this.kpiCategories = categories;
    this.dashboardInformation = dashboardInformation;

    this.openDashboardInformationDialogFirstTime();
  }
}
