import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kpi-info-dialog',
  templateUrl: './kpi-info-dialog.component.html',
  standalone: false,
})
export class KpiInfoDialogComponent {
  @Input() public title: string | undefined;
  @Input() public body: string | undefined;

  constructor(public activeModal: NgbActiveModal) {}
}
